<template>
    <!-- Modal -->
    <div class="modal fade" id="edit-supplier" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Edit Supplier</h5>
                        <!-- <p class="text-muted">Add User.</p> -->
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>

                            <div class="row">
                                <div class="col-md-6 col-sm-12 mt-2" id="name">
                                    <label for="business.name" class="fw-bold mb-2">Enter your Business Name</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'users']" />
                                        </span>

                                        <input id="business.name" type="address" class="form-control" v-model="data.supplier.name" placeholder="Enter your business name.">
                                    </div>
                                </div>
                                
                                <div class="col-md-6 col-sm-12 mt-2">
                                    <label for="business.type" class="fw-bold mb-2">Enter your Supplier Type</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'truck-loading']" />
                                        </span>

                                        <select id="business.type" class="form-select" v-model="data.supplier.type">
                                            <option value="Pharmacy">Pharmacy</option>
                                            <option value="Manuafcturer">Manufacturer</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem;">
                                    <label for="business.corporation_type" class="fw-bold mb-2">Enter your Business Type</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'building']" />
                                        </span>

                                        <select id="business.corporation_type" class="form-select" v-model="data.supplier.corporation_type">
                                            <option value="Sole Proprietorship">Sole Proprietorship</option>
                                            <option value="General Partnership">General Partnership</option>
                                            <option value="Limited Partnership">Limited Partnership</option>
                                            <option value="Limited Partnership">Limited Partnership</option>
                                            <option value="Corporation">Corporation</option>
                                        </select>
                                    </div>
                                    <small>What business type are you registered as? e.g. Partnership or Corporation</small>
                                </div>
                                
                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem;" id="business-type">
                                    <label for="business.type" class="fw-bold mb-2">Upload your Business Certificate</label>  
                                    <div class="input-group">
                                        <input type="file" id="business.business_certificate" class="form-control">
                                    </div>
                                    <small>Upload a DOCX or a PDF</small>
                                </div>                           
                            </div>                    

                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem" id="address">
                                    <label for="business.address" class="fw-bold mb-2">Enter your Business Address</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'location-arrow']" />
                                        </span>

                                        <input id="business.address" type="text" class="form-control" v-model="data.supplier.address" placeholder="Enter your address.">
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem" id="address">
                                    <label for="business.number" class="fw-bold mb-2">Enter your Business Registration Number</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'id-card']" />
                                        </span>

                                        <input id="business.number" type="number" class="form-control" v-model="data.supplier.business_number" placeholder="Enter your Business Registration Number.">
                                    </div>
                                </div>                                                
                            </div> 

                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem" id="address">
                                    <label for="business.email" class="fw-bold mb-2">Enter your Business Email</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'envelope']" />
                                        </span>

                                        <input id="business.email" type="email" class="form-control" v-model="data.supplier.email" placeholder="Enter your business email.">
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem" id="address">
                                    <label for="business.phone_number" class="fw-bold mb-2">Enter your Business Phone Number</label>  
                                    <div class="input-group">
                                        <span class="input-group-text" >
                                            <font-awesome-icon id="lock" :icon="['fa', 'phone-alt']" />
                                        </span>

                                        <input id="business.phone_number" type="number" class="form-control" v-model="data.supplier.phone_number" placeholder="Enter your Business Phone Number.">
                                    </div>
                                </div>                                                
                            </div> 
                            
                        <div class="row" style="margin-top: 2.1rem" id="address">
                                <label for="business.description" class="fw-bold mb-3">Enter a brief description of your business.</label>  
                                <div class="input-group">
                                    <span class="input-group-text" >
                                        <font-awesome-icon id="lock" :icon="['fa', 'users']" />
                                    </span>

                                    <textarea rows="4" id="business.description" type="text" class="form-control" v-model="data.supplier.description" placeholder="Enter your address."  required style="border-left: 1px solid #eee;"></textarea>
                                </div>
                            </div>  
                                                                                              

                            <form action="#" @submit.prevent="handleSubmit()">
                                <button class="btn bg-orange text-uppercase text-white mt-4 w-100">
                                    <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select {
        border: 0;
        border-bottom: 1px solid #eee;
    }
    
    .modal-dialog {
        max-width: 1000px;
    }
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'EditSupplier',
        props: {
            modal_data: {
                required: true,
                type: Object
            }
        },
        watch: {
            modal_data: function() {
                this.data.supplier = this.modal_data
            }
        },     
        components: {
            Status
        },
        data() {
            return {
                screen: 0,
                data: {
                    supplier: {
                        name: null,
                        description: null,
                        address: null,
                        phone_number: null,
                        email: null,
                        business_number: null,
                        corporation_type: null,
                        type: null,
                        id: null
                    }
                },
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true
                let formData = new FormData()
                let image = document.getElementById('business.business_certificate').files[0]

                formData.append("name", this.data.supplier.name)
                formData.append('email', this.data.supplier.email)
                formData.append('phone_number', this.data.supplier.phone_number)
                formData.append('business_number', this.data.supplier.business_number)
                formData.append('type', this.data.supplier.type)
                formData.append('corporation_type', this.data.supplier.corporation_type)
                formData.append('address', this.data.supplier.address)
                formData.append('description', this.data.supplier.description)
                formData.append('business_certificate', image)

                this.axios.put('api/suppliers/' + this.data.supplier.id, formData).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Supplier Added!'
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.data
                    
                    console.log(error);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>