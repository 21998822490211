<template>
    <section-title v-if="title.status == true" :title=title.title :actions=title.actions></section-title>

    <div class="row">
        <div class="col-md-12">
            <div class="input-group mb-3">
                <status v-model:status_data=status_data></status>

                <form action="#" @submit.prevent="getDatabyPage()" style="width: inherit">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control search" v-model="search" placeholder="Search through system." aria-label="search" aria-describedby="search-submit">

                        <span class="input-group-text search-icon" id="search-submit">
                            <button class="btn btn-transparent" type="submit">
                                <font-awesome-icon class="icon" :icon="['fa', 'search']" /> 
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    <div id="tables" class="data-lists row">
        <div class="col-md-12">
            <small class="text-muted fw-bold">{{ totals }} total records</small>
            <small class="text-muted float-end fw-bold">{{ totals }} records showing</small>
            
            <table id="table" class="table table-striped mt-2">
                <thead class="table-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th v-for="(header, index) in headers" :key="index" scope="col">
                            <span class="text-capitalize" v-if="header.indexOf('.') == '-1'">{{ header.split("_").join(" ") }}</span>

                            <span class="text-capitalize" v-if="header.indexOf('.') != '-1'">{{ header.split('.')[1] }}</span>
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody class="border-top-0">
                    <tr v-for="(item, index) in data" :key="index">
                        <th scope="row">{{ getIndex(index) }}</th>
                        <td v-for="(header, index) in headers" :key="index">
                            <span v-if="header.indexOf('.') == '-1'">{{ item[header] }}</span>
                            
                            <span v-if="header.indexOf('.') != '-1'">{{ item[header.split('.')[0]] }}</span>
                        </td>

                        <td class="dropdown">
                            <font-awesome-icon :icon="['fas', 'ellipsis-h']" class="dropdown-toggle" type="button" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false"></font-awesome-icon>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu">
                                <li v-for="({name, action_url, type}, index) in all_screens[screen-1].actions" :key="index">
                                    <a class="dropdown-item" v-if="type == 'api'" @click="autoAPI(action_url, item['id'], action_url.message, $event.currentTarget, 'list')" style="cursor: pointer">{{ name }}</a>

                                    <a class="dropdown-item" v-if="type == 'modal'" style="cursor: pointer" @click="setListData(item, action_url)">{{ name }}</a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div id="cards" class="data-lists row mb-3">
        <div class="col-md-12">
            <small class="text-muted fw-bold">+{{ totals }} total</small>
            <small class="text-muted float-end fw-bold">{{ size }} records showing.</small>
        </div>

        <div class="col-md-12 mt-3" v-for="(item, index) in data" :key="index">
            <div class="card mb-2 border-0">
                <div class="card-body pb-0">
                    <p class="text-dark fw-bold">{{ index + 1 }}. </p>
                    
                    <p class="text-dark mb-3" v-for="(header, index) in headers" :key="index"> 
                        <span class="fw-bold text-uppercase" v-if="header.indexOf('.') == '-1'">{{ header.split("_").join(" ") }}: </span> {{ item[header] }}
                    </p>
                    
                    <!-- <p class="text-dark mb-3" v-for="(header, index) in headers" :key="index"> 
                        <span class="fw-bold text-uppercase" v-if="header.indexOf('.') != '-1'">{{ header.split('.')[1].split("_").join(" ") }}: </span> ss{{ item[header] }}
                    </p> -->
                </div>
                
                <div class="card-footer border-0 bg-white">
                    <span v-for="({name, action_url, type}, index) in all_screens[screen-1].actions" :key="index" >
                        <button class="btn button default btn-sm mt-1" v-if="type == 'modal'" @click="setListData(item, action_url)">
                            <span class="ms-2 me-2">{{ name }}</span>
                        </button>  

                        <button class="btn btn-secondary button btn-sm mt-1" v-if="type =='api'" @click="autoAPI(action_url, item['id'], action_url.message, $event.currentTarget, 'cards')"> 
                            <span class="ms-2 me-2">{{ name }}</span>

                            <font-awesome-icon class="ms-2 icon-loading d-none" :icon="['fas', 'spinner']"></font-awesome-icon>
                            
                            <font-awesome-icon class="ms-2 icon-success d-none" :icon="['fas', 'check-circle']"></font-awesome-icon>

                            <font-awesome-icon class="ms-2 icon-failure d-none" :icon="['fas', 'times-circle']"></font-awesome-icon>
                        </button>         
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row navigation">
        <div class="col-12 d-flex">
            <div class="mx-auto">
                <button class="btn secondary btn-sm me-2" id="start" @click="start()" :disabled="hidePrevious">
                    <span class="mx-1">Start <font-awesome-icon class="icon ms-2" :icon="['fa', 'step-backward']" /></span>
                </button>

                <button class="btn secondary btn-sm me-2" id="previous" @click='previous()' :disabled="hidePrevious">
                    <font-awesome-icon class="icon me-2" :icon="['fa', 'caret-left']" /> Previous
                </button>

                <span>
                    ||
                </span>

                <button class="btn default btn-sm ms-2 me-2" id="next" @click='next()' :disabled="hideNext">
                    Next
                    <font-awesome-icon class="icon ms-2" :icon="['fa', 'caret-right']" />
                </button>

                <button class="btn default btn-sm me-2" id="end" @click="end()" :disabled="hideNext">
                    <span class="mx-1">End <font-awesome-icon class="icon ms-2" :icon="['fa', 'step-forward']" /></span>
                </button>
            </div>
        </div>
    </div>

    <form-component></form-component>

    <edit-user :modal_data=modal_data></edit-user>

    <edit-supplier :modal_data=modal_data></edit-supplier>

    <view-driver-details :modal_data="modal_data" :modal_key="modal_key"></view-driver-details>

    <view-doctor-details :modal_data="modal_data" :modal_key="modal_key"></view-doctor-details>

    <add-stock :modal_data=modal_data></add-stock>

    <edit-stock :modal_data="modal_data" :url="url"></edit-stock>

    <advertise-stock :modal_data=modal_data></advertise-stock>

</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/_lists.scss';
</style>

<script>
import FormComponent from '../components/Form';
import SectionTitle from'../components/SectionTitle';    
import Status from '../components/Status';
import {Modal} from 'bootstrap'
import statusButtons from '../mixins/statusButtons';
import EditUser from '../components/Modals/EditUser'
import EditSupplier from './Modals/EditSupplier';
// import ViewDriverDetails from './Modals/ViewDriverDetails';
import ViewDoctorDetails from './Modals/ViewDoctorDetails';
import AddStock from '../components/Modals/AddStock'
import EditStock from '../components/Modals/EditStock'
import AdvertiseStock from '../components/Modals/AdvertiseStock'

export default {
    name: 'AutoList',
    mixins: [statusButtons],
    props: {
        title: {
            type: Object,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        all_screens: {
            required: true,
            type: Object
        },
        screen: {
            required: true,
            type: Number
        },
        list_data: {
            required: true,
            type: Object
        }
    },
    components: {
        FormComponent,
        SectionTitle,    
        Status,
        EditUser,
        EditSupplier,
        // ViewDriverDetails,
        ViewDoctorDetails,
        AddStock,
        EditStock,
        AdvertiseStock
    },
    watch: {
        url: function() {
            this.getDatabyPage()
            this.hidePrevious = true
            this.hideNext = false
            this.page = 0
            this.filterUrl()
        }
    },    
    mounted() {
        this.getDatabyPage()
    },
    data (){
        return {
            page: 0,
            size: 25,
            last_id: 0,
            data: [],
            search: null,
            totals: 0,
            headers: [],
            hidePrevious: true,
            hideNext: false,
            status_data: {
                "success": false,
                "success_message": 'Form Submission was successful!',
                "error": false,
                "error_message": 'There are some errors.',
                "loading": false
            },
            modalElem: null,
            active_api: null,
            active_item: null,
            filter_url: null,
            
            // custom variables
            modal_data: {},
            modal_key: null
        }
    },
    methods: {
        getDatabyPage() {
            this.axios.get(this.url + '?size=' + this.size + '&page=' + this.page + '&last_id=' + this.last_id + '&search=' + this.search).then((response) => {
                this.data = response.data.data
                this.headers = response.data.headers
                this.totals = response.data.totals
                this.last_id = response.data.last_id
                document.querySelector('.data-lists').scrollIntoView({behavior: "smooth"})
            }).catch((error) => {
                this.error = error
                document.querySelector('.data-lists').scrollIntoView({behavior: "smooth"})
            })
        },
        setListData(data, action_url) {
            this.modal_data = data
            this.modal_key = action_url
            let modal  = document.getElementById(action_url)
            this.modalElem = new Modal(modal)

            this.modalElem.show()
        },
        autoAPI(url, id, message, event, display_type) {
            this.status_data.loading = true
            let new_url = '';
            let type = typeof url.url 
            
            if(display_type == 'cards') {
                this.loadingButton(event)
            }
            
            if(type == 'object') {
                new_url =  url.url[0] + id + url.url[1]
            } else {
                new_url = url.url + id
            }

            this.axios[url.type](new_url).then((response) => {
                console.log(response);
                this.status_data.loading = false
                this.status_data.error = false
                this.status_data.success = true
                this.status_data.success_message = message.success

                if(display_type == 'cards') {
                    this.successButton(event)
                    setTimeout(() => (this.defaultButton(event, 'success')), 2000)
                }

                setTimeout(() => (this.status_data.success = false), 2000)
                this.getDatabyPage() 
            }).catch((error) => {
                this.status_data.loading = false
                this.status_data.error = true
                this.status_data.success = false
                this.status_data.error_message = message.error
                
                if(display_type == 'cards') {
                    this.errorButton(event)
                }

                console.log(error);
            })
        },
        next() {
            if((this.page * this.size) < this.totals) {
                this.page = this.page + 1
                this.getDatabyPage()
            }

            if(this.page > 0) {
                this.hidePrevious = false

                document.getElementById('start').classList.remove('secondary')
                document.getElementById('start').classList.add('default')

                document.getElementById('previous').classList.remove('secondary')
                document.getElementById('previous').classList.add('default')
            }

            if((((this.page+1) * this.size) + this.size) > this.totals) {
                this.hideNext = true

                document.getElementById('end').classList.remove('default')
                document.getElementById('end').classList.add('secondary')

                document.getElementById('next').classList.remove('default')
                document.getElementById('next').classList.add('secondary')
            }
        },
        previous() {
            if(this.page >= 0) {
                this.page = this.page - 1
                this.getDatabyPage()
            }
            
            if(this.page == 0) {
                this.hidePrevious = true

                document.getElementById('start').classList.remove('default')
                document.getElementById('start').classList.add('secondary')

                document.getElementById('previous').classList.remove('default')
                document.getElementById('previous').classList.add('secondary')
            }

            if((((this.page) * this.size) + this.size) < this.totals) {
                this.hideNext = false

                document.getElementById('end').classList.remove('secondary')
                document.getElementById('end').classList.add('default')

                document.getElementById('next').classList.remove('secondary')
                document.getElementById('next').classList.add('default')
            }
                 
        },
        start() {
            this.page = 0
            this.getDatabyPage()

            if(this.hideNext == true) {
                this.hideNext = false

                document.getElementById('end').classList.remove('secondary')
                document.getElementById('end').classList.add('default')

                document.getElementById('next').classList.remove('secondary')
                document.getElementById('next').classList.add('default')
            }

            if(this.hidePrevious == false) {
                this.hidePrevious = true

                document.getElementById('start').classList.remove('default')
                document.getElementById('start').classList.add('secondary')

                document.getElementById('previous').classList.remove('default')
                document.getElementById('previous').classList.add('secondary')                
            }
        },
        end() {
            this.page = Math.floor(this.totals/this.size)-1
            this.getDatabyPage()

            if(this.hideNext == false) {
                this.hideNext = true

                document.getElementById('end').classList.remove('default')
                document.getElementById('end').classList.add('secondary')

                document.getElementById('next').classList.remove('default')
                document.getElementById('next').classList.add('secondary')
            }

            if(this.hidePrevious == true) {
                this.hidePrevious = false

                document.getElementById('start').classList.remove('secondary')
                document.getElementById('start').classList.add('default')

                document.getElementById('previous').classList.remove('secondary')
                document.getElementById('previous').classList.add('default')                
            }
        }, 
        filterUrl() {
            this.filter_url = 'api/filter-' + this.url.split('/')[1]
        },
        fetchResults() {
            this.status_data.loading = true

            let data = {
                name: this.search
            }

            this.axios.post(this.filter_url  + '?size=' + this.size + '&page=' + this.page, data).then((response) => {
                this.status_data.loading = false
                this.status_data.success = true
                this.status_data.success_message = 'Success'
                this.status_data.error = false

                this.data = response.data.data
                this.headers = response.data.headers
                this.totals = response.data.totals

                setTimeout(() => {
                    this.status_data.success = false
                }, 1000);

                document.querySelector('.data-lists').scrollIntoView({behavior: "smooth"})
            }).catch((error) => {
                this.status_data.loading = false
                this.status_data.success = false
                this.status_data.error = true
                this.status_data.error = error.response.data

                setTimeout(() => {
                    this.status_data.error = false
                }, 1000);
                
                document.querySelector('.data-lists').scrollIntoView({behavior: "smooth"})
                console.log(error)
            })
        },    
        getIndex(index) {
            return ((this.page) * this.size) + index + 1
        },      
    }
}
</script>