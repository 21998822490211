<template>
    <!-- Modal -->
    <div class="modal fade" id="featured-ad" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Set Stock in Ad-space</h5>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>
                            <div class="form-row mt-1">
                                <label for="ad" class="fw-bold mb-2">Set Stock in Ad space</label>  
                                <div class="input-group">
                                    <span class="input-group-text border-right-0">
                                        <font-awesome-icon id="eye" :icon="['fa', 'comment']" />
                                    </span>

                                    <select id="ad" type="text" class="form-control no-border" v-model="data.stock.featured_ad" required>
                                        <option :value="1">Set in Ad Space</option>
                                        <option :value="0">Remove from Ad Space</option>
                                    </select>
                                </div>
                            </div>
                                            

                            <form action="#" @submit.prevent="handleSubmit()">
                                <button class="btn bg-orange text-uppercase text-white mt-4 w-100">
                                    <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';
  
    .form-select {
        border: 0;
        border-bottom: 1px solid #eee;
    }
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'EditUser',
        components: {
            Status
        },  
        props: {
            modal_data: {
                required: true,
                type: Object
            }
        },
        watch: {
            modal_data: function() {
                this.data.stock = this.modal_data
            }
        },               
        data() {
            return {
                screen: 0,
                data: { 
                    stock: {}
                },
                modal_datas: null,
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true

                this.axios.put('api/stocks/' + this.data.stock.id, this.data.stock).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Stock Details Updated!'
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.data

                    console.log(error);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>