<template>
    <!-- Modal -->
    <div class="modal fade" id="view-doctor-details" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min text-orange">View Doctor Details</h5>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                          <status v-model:status_data=status_data></status>
                            
                            <h6 class="fst-italic mb-0 text-dark">Doctor's personal details</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <ul class="list-group mt-3">
                                <li class="list-group-item"><span class="fw-bold">Name: </span> {{ data.user.name }}</li>

                                <li class="list-group-item"><span class="fw-bold">Email: </span> {{ data.user.email }}</li>

                                <li class="list-group-item"><span class="fw-bold">Phone Number: </span> {{ data.user.phone_number }}</li>

                                <li class="list-group-item"><span class="fw-bold">Gender: </span> {{ data.user.kyc.gender }}</li>
                            </ul>
                        </div>

                        <div class="col-md-6 col-sm-12">
                            <ul class="list-group mt-3">
                                <li class="list-group-item"><span class="fw-bold">Marital: </span> {{ data.user.kyc.marital }}</li>

                                <li class="list-group-item"><span class="fw-bold">ID Number: </span> {{ data.user.kyc.idno }}</li>

                                <li class="list-group-item"><span class="fw-bold">Date of Birth: </span> {{ data.user.kyc.dob }}</li>

                                <li class="list-group-item"><span class="fw-bold">Address: </span> {{ data.user.kyc.address }}</li>
                            </ul>
                        </div>

                        <div class="col-12">
                            <h6 class="fst-italic mt-4 mb-0 text-dark">Professional Details</h6>

                            <small class="text-muted">Log all vehicles driver users</small>

                            <ul class="list-group mt-3">
                                <li class="list-group-item"><span class="fw-bold">Title: </span> {{ data.user.doctor.title }}</li>

                                <li class="list-group-item"><span class="fw-bold">Qualifications: </span> {{ data.user.doctor.qualifications }}</li>

                                <li class="list-group-item"><span class="fw-bold">Specialty: </span> {{ data.user.doctor.specialty }}</li>

                                <li class="list-group-item"><span class="fw-bold">School Attended: </span> {{ data.user.doctor.school_attended }}</li>

                                <li class="list-group-item"><span class="fw-bold">Employer: </span> {{ data.user.doctor.employer }}</li>

                                <li class="list-group-item"><span class="fw-bold">Medical Facility: </span> {{ data.user.doctor.medical_facility }}</li>
                                
                                <li class="list-group-item"><span class="fw-bold">Registration Body: </span> {{ data.user.doctor.registration_body }}</li>

                                <li class="list-group-item"><span class="fw-bold">Registration Licence No.: </span> {{ data.user.doctor.registration_licence_no }}</li>
                            </ul>

                            <div class="row mt-4">
                                <div class="col">
                                    <button class="btn bg-transparent text-dark w-25 fw-bold" data-bs-dismiss="modal">
                                        <font-awesome-icon class="me-2" :icon="['fa', 'times']"></font-awesome-icon> Close 
                                    </button>

                                    <button class="btn bg-orange text-white w-75" @click="verifyDoctor()">Verify Doctor</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select {
        border: 0;
        border-bottom: 1px solid #eee;
    }

    .icon {
        color: #fff;
        background-color: #666666;
        font-weight: 700;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        padding: 8px;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        vertical-align: -6px;
    }
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'ViewDriverDetails',
        components: {
            Status
        },
        props: {
            modal_data: {
                required: true,
                type: Object
            },
            modal_key: {
                required: true,
                type: String
            }
        },        
        watch: {
            modal_data: function() {
                if(this.modal_key == 'view-doctor-details') {
                    this.data.user = this.modal_data
                }
            }
        },         
        data() {
            return {
                screen: 0,
                data: {
                    user: {
                        name: null, 
                        email: null, 
                        phone_number: null,
                        kyc: {
                            gender: null,
                            marital: null,
                            idno: null,
                            address: null,
                            dob: null,
                        },
                        doctor: {
                            title: null,
                            qualifications: null,
                            specialty: null,
                            school_attended: null,
                            employer: null,
                            medical_facility: null,
                            registration_body: null,
                            registration_licence_no: null
                        }
                    }
                },
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null,
                    "loading": false
                },
            }
        },
        methods: {
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
            verifyDoctor() {
                this.status_data.loading = true

                let data = {
                    doctor_verified: true
                }

                this.axios.put('api/users/' + this.data.user.id, data).then(() => {
                    this.status_data.loading = false
                    this.status_data.success = true
                    this.status_data.error = false
                    this.status_data.success_message = 'Verified!'
                }).catch(errors => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                    this.status_data.error_message = errors.message
                })
            }
        }
    }
</script>