<template>
    <!-- Modal -->
    <div class="modal fade" id="edit-stock" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Edit stock</h5>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>
                            <span v-if="screen == 0">
                                <div class="form-row">
                                    <label class="mb-2 fw-bold" for="name">Name</label>

                                    <div class="input-group">
                                        <input type="text" v-model="data.name" class="form-control">
                                    </div>
                                    <small class="d-block">Enter the stock name.</small>
                                    <small v-if="errors.name" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.name }}
                                    </small>                                    
                                </div> 

                                <div class="form-row mt-4">
                                    <label class="mb-2 fw-bold" for="stock_type">Stock Type</label>

                                    <div class="input-group">
                                        <select id="stock_type" v-model="data.stock_type" class="form-select">
                                            <option></option>
                                            <option :value="name" v-for="({name}, index) in stock_types" :key="index">{{ name }}</option>
                                        </select>
                                    </div>

                                    <small v-if="errors.stock_type" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.stock_type }}
                                    </small> 
                                </div>

                                <div class="form-row mt-4">
                                    <label class="mb-2 fw-bold" for="category">Category</label>

                                    <div class="input-group">
                                        <select id="category" v-model="data.category" class="form-select">
                                            <option></option>
                                            <option :value="name" v-for="({name}, index) in categories" :key="index">{{ name }}</option>
                                        </select>
                                    </div>

                                    <small v-if="errors.category" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.category }}
                                    </small> 
                                </div>                                

                                <div class="row mt-4">
                                    <div class="col d-flex">
                                        <button class="btn bg-dark text-white w-100 me-2" @click="nextScreen()">
                                            <span class="m-0 text-uppercase" style="letter-spacing: 1px">Next </span> <font-awesome-icon class="ms-3" :icon="['fas', 'angle-right']"></font-awesome-icon>
                                        </button>  

                                        <button class="btn btn-secondary me-2" data-bs-dismiss="modal">
                                            <span class="m-0 text-uppercase" style="letter-spacing: 1px">
                                                <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                                            </span>
                                        </button> 
                                    </div>
                                </div>                  
                            </span>
                            
                            <span v-if="screen == 1">
                                <div class="form-row">
                                    <label class="mb-2 fw-bold" for="prescription">Prescription</label>

                                    <div class="input-group">
                                        <select v-model="data.prescription" id="prescription" class="form-select">
                                            <option></option>
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                    </div>
                                    <small class="d-block">Enter whether the drug requires prescription or not.</small>

                                    <small v-if="errors.prescription" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.prescription }}
                                    </small>
                                </div> 

                                <div class="form-row mt-4">
                                    <label class="mb-2 fw-bold" for="image_url">Upload Image of Stock</label>

                                    <div class="input-group">
                                        <input type="file" class="form-control" id="image_url" required @change="imageEvent($event)">
                                    </div>

                                    <small v-if="errors.image_url" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.image_url }}
                                    </small>
                                </div>

                                <div class="form-row mt-4">
                                    <label class="mb-2 fw-bold" for="type_of_use">Price</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control" v-model="data.prices[data.prices.length-1].price">
                                    </div>
                                    <small class="d-block">Enter Selling Price of Stock</small>

                                    <small v-if="errors.price" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.price }}
                                    </small>
                                </div>
                                
                                <div class="row mt-4">
                                    <div class="col d-flex">
                                        <button class="btn btn-secondary w-25 me-2" @click="previousScreen()">
                                            <font-awesome-icon class="me-3" :icon="['fas', 'angle-left']"></font-awesome-icon>
                                        </button>

                                        <button class="btn bg-dark text-white w-75 me-2" @click="nextScreen()">
                                            <span class="m-0 text-uppercase" style="letter-spacing: 1px">Next </span> <font-awesome-icon :icon="['fas', 'angle-right']"></font-awesome-icon>
                                        </button>      

                                        <button class="btn btn-secondary me-2" data-bs-dismiss="modal">
                                            <span class="m-0 text-uppercase" style="letter-spacing: 1px">
                                                <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                                            </span>
                                        </button>                                                                           
                                    </div>    
                                </div>                                
                            </span>
                            
                            <span v-if="screen == 2">                       
                                <div class="form-row">
                                    <label class="mb-2 fw-bold" for="tags">Tags</label>

                                    <div class="input-group">
                                        <textarea class="form-control" v-model="data.tags" id="tags" rows="4"></textarea>
                                    </div>
                                    <small class="d-block">Enter comma-separated tags for the current stock</small>
                                    
                                    <small v-if="errors.tags" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.tags }}
                                    </small>
                                </div>

                                <div class="form-row mt-4">
                                    <label class="mb-2 fw-bold" for="name">Description</label>

                                    <div class="input-group">
                                        <textarea class="form-control" v-model="data.description" id="description" rows="4"></textarea>
                                    </div>

                                    <small v-if="errors.description" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.description }}
                                    </small>
                                </div>

                                <div class="form-row">
                                    <label class="mb-2 fw-bold" for="featured">Set Featured</label>

                                    <div class="input-group">
                                        <select v-model="data.featured" id="featured" class="form-select">
                                            <option></option>
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                    </div>
                                    <small class="d-block">Show product in home page</small>

                                    <small v-if="errors.featured" class="text-danger">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                        {{ errors.featured }}
                                    </small>
                                </div> 

                                <div class="row mt-4">
                                    <div class="col d-flex">
                                        <button class="btn btn-secondary w-25 me-2" @click="previousScreen()">
                                            <font-awesome-icon :icon="['fas', 'angle-left']"></font-awesome-icon>
                                        </button>

                                        <button class="btn bg-orange text-white w-75 me-2" @click="handleSubmit()">
                                            <span class="m-0 text-uppercase" style="letter-spacing: 1px">Finish </span> <font-awesome-icon class="ms-3" :icon="['fas', 'angle-right']"></font-awesome-icon>
                                        </button>    
                                        
                                        <button class="btn btn-secondary" data-bs-dismiss="modal">
                                            <span class="m-0 text-uppercase" style="letter-spacing: 1px">
                                                <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                                            </span>
                                        </button>                                                                              
                                    </div>    
                                </div>                          
                            </span>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select, .form-control {
        border: 1px solid #ced4da;
    }    
</style>

<script>
    import { devNull } from 'os';
import Status from '../../components/Status';

    export default {
        name: 'EditStock',
        props: {
            supplier_id: {
                required: false,
                type: Number
            },
            modal_data: {
                required: true,
                type: Object
            },
            url: {
                required: false,
                type: String
            }
        },
        components: {
            Status
        },
        watch: {
            modal_data: function() {
                this.data = this.modal_data
            }
        },  
        data() {
            return {
                screen: 0,
                data: {                        
                    name: null,
                    stock_type: null,
                    image_url: null,
                    description: null,
                    price: null,
                    prescription: null,
                    category: null,
                    featured: devNull,
                    tags: null
                },
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null,
                    "loading": false
                },
                categories: [
                    {
                        name: 'Diabetes',
                        image: 'diabetes.jpg'
                    },
                    {
                        name: 'Hypertension',
                        image: 'hypertension.jpg'
                    },
                    {
                        name: 'Beauty',
                        image: 'beauty.jpg'
                    },
                    {
                        name: 'Feminine Care',
                        image: 'feminine-care.jpg'
                    },
                    {
                        name: 'Baby and Kids',
                        image: 'baby.jpg'
                    },
                    {
                        name: 'Sexual Wellness',
                        image: 'sex-well.jpg'
                    },
                    {
                        name: 'Cough, Cold & Flu',
                        image: 'syrup.jpg'
                    },
                    {
                        name: 'Medical Devices',
                        image: 'medical-devices.jpg'
                    },
                    {
                        name: 'Allergy & Sinus',
                        image: 'allergies.jpg'
                    },
                    {
                        name: 'First Aid',
                        image: 'first-aid.jpg'
                    },
                    {
                        name: 'Pain Relief',
                        image: 'pain-relief.jpg'
                    },
                    {
                        name: 'Digestive Health',
                        image: 'digestive-health.jpg'
                    },
                    {
                        name: 'Home Tests',
                        image: 'home-tests.jpg'
                    },
                    {
                        name: 'Skin Ailments',
                        image: 'skin-ailments.jpg'
                    },
                    {
                        name: 'Eye Care',
                        image: 'eye-care.jpg'
                    },
                    {
                        name: 'Ear, Nose and Throat Care  ',
                        image: 'ear-nose.jpg'
                    } 
                ],
                stock_types: [
                    {
                        name: 'tablet'
                    },
                    {
                        name: 'syrup'
                    },
                    {
                        name: 'suppository'
                    },
                    {
                        name: 'capsule'
                    },
                    {
                        name: 'pessary'
                    }
                ],  
                errors: {}
            }
        },
        methods: {
            imageEvent(event) {
                this.data.image_url = event
            },
            handleSubmit() {
                this.status_data.loading = true
                let data = new FormData();
                
                data.append('name', this.data.name)
                data.append('stock_type', this.data.stock_type)

                if(this.data.image_url && this.data.image_url.target) {
                    data.append('image_url', data.append('image_url', this.data.image_url.target.files[0]))
                }

                data.append('description', this.data.description)
                data.append('prescription', this.data.prescription)
                data.append('featured', this.data.featured)
                data.append('category', this.data.category)
                data.append('tags', this.data.tags)
                data.append('price', this.data.price)

                this.axios.post('api/stocks/edit/' + this.data.id, data).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Stock Updated!'

                    this.data = this.modal_data

                }).catch(errors => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = 'Errors found!'
                    this.errors = errors.data

                    console.log(errors.data);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>   